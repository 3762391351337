<template>
  <div>
    Successfully saved!
  </div>
</template>

<script>
export default {
  name: "notification-template",
};
</script>
